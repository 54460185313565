import { FC } from 'react';
import { Modal, RichText } from '@/shared/ui-kit';
import { Button } from '@mantine/core';
import { ResultProps } from './result-modal.types';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { BottomSheet } from '@/shared/ui-kit/bottom-sheet/bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import styles from './result-modal.module.scss';

export const ResultModal: FC<ResultProps> = ({ opened, close, title, info, additional }) => {
  const { isMobile } = useMediaQuery();
  const content = (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {title && <div className={styles.title}>{title}</div>}
        {info && <RichText className={styles.info}>{info}</RichText>}
      </div>
      {additional}
      <Button onClick={close} className={styles.button}>
        Понятно
      </Button>
    </div>
  );

  return isMobile ? (
    <BottomSheet open={opened} onDismiss={close} className={styles.bottomsheet}>
      {content}
    </BottomSheet>
  ) : (
    <Modal opened={opened} onClose={close} contentClassName={styles.modal} className={styles.closeButton}>
      {content}
    </Modal>
  );
};
