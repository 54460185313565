export type Slide = {
  path: string;
  alt: string;
  href?: string;
  mediaFormat?: string;
};

export const carouselMessages = {
  firstSlideMessage: '',
  lastSlideMessage: '',
  nextSlideMessage: '',
  prevSlideMessage: '',
};
