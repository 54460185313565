import { FC, ReactNode, memo, useEffect, useState } from 'react';
import { Radio, RadioGroupProps } from '@mantine/core';
import { isToday } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import format from 'date-fns/format';
import cn from 'classnames';
import styles from './time-picker.module.scss';

interface TimePickerProps extends Partial<RadioGroupProps> {
  initialValue?: string;
  changeHandler?: (d: Date) => void;
  date?: Date;
}

const TimePicker: FC<TimePickerProps> = memo(({ date = new Date(), initialValue, changeHandler, ...props }) => {
  const [selectedTime, setSelectedTime] = useState<string | undefined>(initialValue || '');
  const today = isToday(date);
  const hours = utcToZonedTime(new Date(), 'Europe/Moscow').getHours();

  useEffect(() => {
    const time = today ? String(hours + 1) : String(9);
    setSelectedTime(time);
    changeHandler && changeHandler(new Date(date.setHours(Number(time))));
  }, [changeHandler, hours, today]);

  const onChange = (selectedTime: string) => {
    setSelectedTime(selectedTime);
    changeHandler && changeHandler(new Date(date.setHours(Number(selectedTime))));
  };

  const items: ReactNode[] = [];

  for (let i = 9; i < 18; i++) {
    const disabled = today && hours + 1 > i;

    items.push(
      <Radio
        classNames={{
          root: styles.root,
          inner: styles.inner,
          label: styles.label,
        }}
        key={i}
        value={String(i)}
        className={cn({ [styles.disabled]: disabled, [styles.selected]: selectedTime === String(i) })}
        disabled={disabled}
        label={
          <div>
            {format(new Date().setHours(i, 0), 'HH:mm')}
            {' - '}
            {format(new Date().setHours(i + 1, 0), 'HH:mm')}
          </div>
        }
      />,
    );
  }

  return (
    <Radio.Group className={styles.container} value={selectedTime} onChange={onChange} {...props}>
      {items}
    </Radio.Group>
  );
});

TimePicker.displayName = 'TimePicker';
export { TimePicker };
