import { CONSTANTS, SECTIONS_NAMES, Sections } from '@/widgets/form/constants';
import styles from './control.module.scss';
import { FC, memo } from 'react';

interface ControlProps {
  field: (typeof SECTIONS_NAMES)[Sections];
  fieldValue?: string;
  filled?: boolean;
  disabled?: boolean;
}

const Control: FC<ControlProps> = memo(({ field, fieldValue, filled, disabled }) => {
  
  if (field === SECTIONS_NAMES[Sections.delivery]) {
    return filled ? (
      <div className={styles.wrapper}>
        <div className={styles.subtitle}>{CONSTANTS.RADIOS[field as keyof typeof CONSTANTS.RADIOS].control}</div>
        <div className={styles.selected}>
          {
            CONSTANTS.RADIOS[field as keyof typeof CONSTANTS.RADIOS].items.find(({ value }) => value === fieldValue)
              ?.label
          }
        </div>
      </div>
    ) : (
      <div className={disabled ? styles.title__disabled : styles.title}>{CONSTANTS.RADIOS[field as keyof typeof CONSTANTS.RADIOS].control}</div>
    );
  }

  return filled ? (
    <div className={styles.wrapper}>
      <div className={styles.subtitle}>
        {field === SECTIONS_NAMES[Sections.info] ? CONSTANTS.info.control : CONSTANTS.confirmation.control}
      </div>
      <div className={styles.selected}>
        {field === SECTIONS_NAMES[Sections.info] ? CONSTANTS.info.filledControl : CONSTANTS.confirmation.filledControl}
      </div>
    </div>
  ) : (
    <div className={disabled ? styles.title__disabled : styles.title}>
      {field === SECTIONS_NAMES[Sections.info] ? CONSTANTS.info.control : CONSTANTS.confirmation.control}
    </div>
  );
});

Control.displayName = 'Control';
export { Control };
