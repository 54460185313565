import { Radio as MantineRadio, RadioProps as MantineRadioProps } from '@mantine/core';
import styles from './radio.module.scss';
import { FC } from 'react';

export const Radio: FC<MantineRadioProps> = ({ ...props }) => {
  return (
    <MantineRadio
      classNames={{
        label: styles.label,
        body: styles.body,
        labelWrapper: styles.label__wrapper,
        description: styles.description,
        inner: styles.radio__inner,
        radio: styles.radio,
        icon: styles.icon,
        error: styles.error,
      }}
      {...props}
    />
  );
};
