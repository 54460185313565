import { StrapiCollectionResponse, StrapiCollectionResponseMappedType } from '../types/strapi.types';

export function strapiCollectionResponseMapper<T extends object>(
  response?: StrapiCollectionResponse<T>,
): StrapiCollectionResponseMappedType<T> {
  if (response?.data) {
    return response.data.map(({ attributes, id }) => ({ ...attributes, id: id }));
  } else {
    return [];
  }
}
