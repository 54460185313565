import React, { FC, memo, useState } from 'react';
import { CloseBtn } from '@/shared/assets/icons';
import { volnaTheme as theme } from '@/shared/constants/theme';
import cn from 'classnames';
import styles from './email-input.module.scss';
import { Input, InputProps } from '@mantine/core';
import { useValidatedState } from '@mantine/hooks';
import { ErrorIcon } from '@/shared/assets/svg';
import { isValidEmail } from '@/shared/lib/validation';

interface EmailInputProps extends InputProps {
  onChange?: (value: string) => void;
  darkBackground?: boolean;
  label?: string;
  placeholder?: string;
  initialValue?: string;
  withoutValidation?: boolean;
  maxLength?: number;
}

const EmailInput: FC<EmailInputProps> = memo(
  ({
    onChange: changeHandler,
    darkBackground,
    error,
    label,
    placeholder = 'mail@mail.ru',
    initialValue,
    className,
    withoutValidation,
    ...props
  }) => {
    const [{ value, valid }, setValue] = useValidatedState(
      initialValue,
      (val) => (withoutValidation ? true : val ? /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val) : true),
      true,
    );
    const [isActive, setIsActive] = useState(false);

    const onChange = (val: string) => {
      if (isValidEmail(val)) {
        changeHandler && changeHandler(val);
        setValue(val);
      }
    };

    return (
      <Input.Wrapper
        classNames={{
          root: cn(styles.wrapper__root, darkBackground && styles.dark, className && className),
          label: styles.wrapper__label,
          error: styles.wrapper__error,
        }}
        error={error || !valid}
        label={label}
      >
        <Input
          classNames={{
            input: styles.input__input,
            rightSection: styles.input__right_section,
            wrapper: styles.input__wrapper,
          }}
          error={error || !valid}
          value={value}
          placeholder={placeholder}
          onInput={(event) => onChange(event.currentTarget.value)}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          onFocusCapture={() => setIsActive(true)}
          onBlurCapture={() => setIsActive(false)}
          rightSectionWidth={50}
          rightSection={
            error ? (
              <ErrorIcon />
            ) : (
              <button
                type='button'
                onClick={() => onChange('')}
                className={cn(styles.closeBtn, { [styles.visible]: value && isActive })}
              >
                <CloseBtn color={darkBackground ? theme.colors.base[200] : theme.colors.base[400]} />
              </button>
            )
          }
          {...props}
        />
      </Input.Wrapper>
    );
  },
);

EmailInput.displayName = 'EmailInput';
export { EmailInput };
