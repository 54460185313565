export const EVPATORIA_NAME = 'evpatoria';
export const DIY_TARIFF_NAME = 'diy';
export const DIY_TARIFF_LOCALIZED_NAME = 'Сделай сам';
export const DIY_CONSTANTS = {
  RUSSIA_MINUTES: {
    LABEL: 'минут по России',
    VALUE: '25',
  },
};

export const minutesCrimea = {
  0: 0,
  10: 10.551776,
  20: 20.687791,
  30: 30.408044,
  40: 39.712535,
  50: 48.601265,
  60: 57.074233,
  70: 65.131439,
  80: 72.772883,
  90: 79.998565,
  100: 86.808486,
  110: 93.202645,
  120: 99.181043,
  130: 104.743679,
  140: 109.890553,
  150: 114.621665,
  160: 118.937015,
  170: 122.836604,
  180: 126.320431,
  190: 129.388496,
  200: 132.0408,
  210: 134.277342,
  220: 139.83667,
  230: 145.320104,
  240: 150.727644,
  250: 156.059291,
  260: 161.315044,
  270: 166.494903,
  280: 171.598869,
  290: 176.62694,
  300: 181.579118,
  310: 186.455403,
  320: 191.255793,
  330: 195.98029,
  340: 199.999968,
  350: 205.201602,
  360: 209.698417,
  370: 214.119339,
  380: 218.464367,
  390: 222.733501,
  400: 226.926742,
  410: 231.044089,
  420: 235.085542,
  430: 239.051101,
  440: 242.940766,
  450: 246.754538,
  460: 250.492416,
  470: 254.1544,
  480: 257.740491,
  490: 261.250688,
  500: 264.684991,
  510: 268.0434,
  520: 271.325916,
  530: 274.532537,
  540: 277.663265,
  550: 280.7181,
  560: 283.69704,
  570: 286.600087,
  580: 289.42724,
  590: 292.178499,
  600: 294.853865,
  610: 297.453337,
  620: 299.976915,
  630: 302.424599,
  640: 304.79639,
  650: 307.092287,
  660: 309.31229,
  670: 311.456399,
  680: 313.524615,
  690: 315.516937,
  700: 317.433365,
  710: 319.273899,
  720: 321.03854,
  730: 322.727286,
  740: 324.34014,
  750: 325.877099,
  760: 327.338165,
  770: 328.723336,
  780: 330.032615,
  790: 331.265999,
  800: 332.42349,
  810: 333.505087,
  820: 334.51079,
  830: 335.440599,
  840: 336.294515,
  850: 337.072537,
  860: 337.774665,
  870: 338.400899,
  880: 338.95124,
  890: 339.425687,
  900: 339.82424,
  910: 340.146899,
  920: 340.393665,
  930: 340.564537,
  940: 340.659515,
  950: 340.6786,
  960: 340.62179,
  970: 340.489087,
  980: 340.280491,
  990: 339.996,
  1000: 350,
};

export const minutesRussia = {
  0: 0,
  5: 9.63243,
  10: 19.19756,
  15: 28.6954,
  20: 38.12593,
  25: 47.4999,
  30: 56.76793,
  35: 66.00874,
  40: 75.18653,
  45: 84.30132,
  50: 93.3531,
  55: 102.34188,
  60: 111.26765,
  65: 120.13042,
  70: 128.93018,
  75: 137.66693,
  80: 146.34068,
  85: 154.96162,
  90: 164.06076,
  95: 173.12388,
  100: 182.08561,
  105: 191.03232,
  110: 200.0,
  115: 205.29274,
  120: 213.46244,
  125: 221.56913,
  130: 229.61282,
  135: 237.5935,
  140: 245.51118,
  145: 253.36585,
  150: 261.15751,
  155: 268.88617,
  160: 276.55182,
  165: 284.15446,
  170: 291.6941,
  175: 299.17074,
  180: 306.58436,
  185: 313.93498,
  190: 321.2226,
  195: 328.44721,
  200: 335.60881,
  205: 342.70741,
  210: 349.743,
  215: 356.71558,
  220: 363.62516,
  225: 370.47174,
  230: 377.2553,
  235: 383.97586,
  240: 390.63342,
  245: 397.22797,
  250: 403.75951,
  255: 410.22805,
  260: 416.63358,
  265: 422.9761,
  270: 429.25562,
  275: 435.47214,
  280: 441.62564,
  285: 447.71614,
  290: 453.74364,
  295: 459.70813,
  300: 465.60961,
  305: 471.44809,
  310: 477.22356,
  315: 482.93602,
  320: 488.58548,
  325: 494.17194,
  330: 499.69538,
  335: 505.15582,
  340: 510.55326,
  345: 515.88769,
  350: 521.15911,
  355: 526.36753,
  360: 531.51294,
  365: 536.59534,
  370: 541.61474,
  375: 546.57113,
  380: 551.46452,
  385: 556.2949,
  390: 561.06228,
  395: 565.76664,
  400: 570.40801,
  405: 574.98636,
  410: 579.50172,
  415: 583.95406,
  420: 588.3434,
  425: 592.66973,
  430: 596.93306,
  435: 601.13338,
  440: 605.27069,
  445: 609.345,
  450: 613.3563,
  455: 617.3046,
  460: 621.18989,
  465: 625.01218,
  470: 628.77145,
  475: 632.46773,
  480: 636.10099,
  485: 639.67125,
  490: 643.17851,
  495: 646.62276,
  500: 650.004,
};

export const traffic = {
  0: 0.0,
  1: 8.59087,
  2: 20.36172,
  3: 30.54258,
  4: 40.0,
  5: 50.0,
  6: 59.58857,
  7: 69.52,
  8: 78.59556,
  9: 88.42,
  10: 96.97273,
  11: 106.67,
  12: 114.73846,
  13: 124.3,
  14: 131.87067,
  15: 141.29,
  16: 148.37647,
  17: 157.65,
  18: 164.24532,
  19: 173.37006,
  20: 181.82182,
  21: 190.91291,
  22: 199.99998,
  23: 209.09089,
  24: 216.70569,
  25: 225.73509,
  26: 234.09794,
  27: 243.10171,
  28: 251.38765,
  29: 260.36578,
  30: 268.57481,
  31: 277.52731,
  32: 285.65943,
  33: 294.58629,
  34: 302.6415,
  35: 311.54272,
  36: 319.52102,
  37: 328.39661,
  38: 336.298,
  39: 345.14794,
  40: 352.97243,
  41: 361.79674,
  42: 369.54431,
  43: 378.34298,
  44: 386.01365,
  45: 394.78668,
  46: 402.38044,
  47: 411.12784,
  48: 418.64468,
  49: 427.36644,
  50: 434.80638,
  51: 443.5025,
  52: 450.86553,
  53: 459.53602,
  54: 466.82213,
  55: 475.46699,
  56: 482.67619,
  57: 491.29541,
  58: 498.4277,
  59: 507.02128,
  60: 514.07666,
  61: 522.64461,
  62: 529.62308,
  63: 538.16539,
  64: 545.06695,
  65: 553.58362,
  66: 560.40828,
  67: 568.89931,
  68: 575.64706,
  69: 584.11245,
  70: 590.78329,
  71: 599.22305,
  72: 605.81697,
  73: 614.2311,
  74: 620.74811,
  75: 629.1366,
  76: 635.57671,
  77: 643.93956,
  78: 650.30275,
  79: 658.63997,
  80: 664.92625,
  81: 673.23783,
  82: 679.4472,
  83: 687.73314,
  84: 693.86561,
  85: 702.12591,
  86: 708.18147,
  87: 716.41614,
  88: 722.39478,
  89: 730.60381,
  90: 736.50555,
  91: 744.68894,
  92: 750.51377,
  93: 758.67153,
  94: 764.41944,
  95: 772.55157,
  96: 778.22257,
  97: 786.32906,
  98: 791.92315,
  99: 800.004,
  100: 950.0,
};

export const sms = {
  0: 0,
  50: 37.965,
  100: 75,
  500: 150,
};
