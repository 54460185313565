import { BUILDING_TYPE } from '@/store/api/service-home-internet/service-home-internet.type';
import { AdditionalServiceItem, ServiceItem } from '@/store/slice/home-internet.slice';

export const splitFirstWord = (text: string) => {
  const [firstWord, ...rest] = text.split(' ');

  return [firstWord, rest.join(' ')];
};

type ServiceType = 'tv' | 'equipment' | 'subscription';
type ServiceTypeParam = ServiceType[];

interface HomeInternetSlice {
  tariffName: string | undefined | null;
  tv: ServiceItem;
  buildingType: BUILDING_TYPE;
  homeInternetTouchedServices: AdditionalServiceItem[];
}

export const getGoalParams = (homeInternetSlice: HomeInternetSlice) => {
  const getSpecificTouchedService = (
    touchedServices: AdditionalServiceItem[],
    serviceType: ServiceTypeParam,
  ): string[] =>
    touchedServices.reduce<string[]>((acc, curr) => {
      if (serviceType.includes(curr.service_type as ServiceType)) {
        acc.push(curr.name);
      }

      return acc;
    }, []);

  const { tariffName, tv, buildingType, homeInternetTouchedServices } = homeInternetSlice;

  const equipment = getSpecificTouchedService(homeInternetTouchedServices, ['equipment']).filter(
    (serviceName) => !serviceName.includes('sberbox'),
  );

  const boxServices = getSpecificTouchedService(homeInternetTouchedServices, ['equipment']).filter((serviceName) =>
    serviceName.includes('sberbox'),
  );

  return {
    tariff: tariffName || '',
    services: [tv.name, ...boxServices],
    equipment,
    subscriptions: getSpecificTouchedService(homeInternetTouchedServices, ['subscription']),
    building_type: buildingType,
  };
};