import { FC } from 'react';
import Image from 'next/image';
import cn from 'classnames';
import { Feature } from '@/store/api/tariff-mobile/tariff-mobile.type';
import { RichText } from '@/shared/ui-kit';
import styles from './features.module.scss';

interface FeaturesProps {
  isPurple?: boolean;
  data: Partial<Feature>[];
  isBasket?: boolean;
}

export const Features: FC<FeaturesProps> = ({ data, isPurple, isBasket }) => {
  return (
    <div
      className={cn(
        styles.container,
        { [styles.container__purple]: isPurple },
        { [styles.container__basket]: isBasket },
      )}
    >
      {data.map(({ white_image, purple_image, text }, index) => {
        const src = isPurple ? purple_image?.data?.attributes?.url : white_image?.data?.attributes?.url;

        return (
          <div className={cn(styles.row, { [styles.row__purple]: isPurple })} key={`${index}-${text}`}>
            {src && <Image height={20} width={20} src={src} alt='icon' className={styles.image} />}
            <RichText whiteText={!isPurple} className={styles.text}>
              {text}
            </RichText>
          </div>
        );
      })}
    </div>
  );
};
