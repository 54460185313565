import { FC, memo, useState } from 'react';
import { Button, Modal, ScrollArea } from '@mantine/core';
import { addDays } from 'date-fns';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { CloseBtn } from '@/shared/assets/icons';
import { BottomSheet } from '@/shared/ui-kit/bottom-sheet/bottom-sheet';
import { Calendar, TimePicker } from '..';
import styles from './date-time-picker.module.scss';

interface DateTimePickerProps {
  opened: boolean;
  date?: Date;
  handler?: (d: Date) => void;
  close?: () => void;
}

const DateTimePicker: FC<DateTimePickerProps> = memo(({ opened, date = new Date(), handler, close }) => {
  const [value, setValue] = useState(date);
  const { isMobile } = useMediaQuery();

  const onReady = () => {
    handler && handler(value);
    close && close();
  };

  const body = (
    <div className={styles.container}>
      <div className={styles.section}>
        <span>Выберите дату звонка</span>
        <Calendar selected={value} onChange={(d) => d && setValue(d)} maxDate={addDays(new Date(), 30)} />
      </div>
      <div className={styles.section}>
        <span>Выберите время звонка</span>
        <TimePicker date={value} changeHandler={setValue} />
      </div>
      <Button className={styles.button} onClick={onReady}>
        Далее
      </Button>
    </div>
  );

  return isMobile ? (
    <BottomSheet open={opened} className={styles.root} header={null} onDismiss={onReady}>
      {body}
    </BottomSheet>
  ) : (
    <Modal
      centered
      opened={opened}
      onClose={onReady}
      classNames={{
        content: styles.modal__content,
        header: styles.modal__header,
        body: styles.modal__body,
      }}
    >
      <div onClick={onReady} className={styles.modal__close}>
        <CloseBtn color='#414961' />
      </div>
      <ScrollArea.Autosize
        mah='calc(100vh - 184px)'
        h='fit-content'
        type='always'
        offsetScrollbars
        scrollbarSize='8px'
        classNames={{ scrollbar: styles.scroll__bar, thumb: styles.scroll__thumb }}
      >
        {body}
      </ScrollArea.Autosize>
    </Modal>
  );
});

DateTimePicker.displayName = 'DateTimePicker';
export { DateTimePicker };
