import { Radio as MantineRadio, RadioGroupProps as MantineRadioGroupProps } from '@mantine/core';
import { Radio } from '.';
import { FC, memo } from 'react';
import styles from './radio.module.scss';

interface RadioGroupProps extends Partial<MantineRadioGroupProps> {
  items: {
    value: string;
    label?: string;
    description?: string;
  }[];
}

const RadioGroup: FC<RadioGroupProps> = memo(({ items, ...props }) => {
  return (
    <MantineRadio.Group {...props} classNames={{ root: styles.group__root }}>
      {items.map((item, index) => (
        <Radio {...item} key={`radio-button-${item.value}-${index}`} />
      ))}
    </MantineRadio.Group>
  );
});

RadioGroup.displayName = 'RadioGroup';
export { RadioGroup };
