import React, { FC } from 'react';
import cn from 'classnames';
import TariffCardOption from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option';
import { TariffCardOptionProps } from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option.types';
import styles from './home-internet-card-content.module.scss';

interface HomeInternetCardContentProps {
  optionsHomeInternet: TariffCardOptionProps[];
  mobileOptions?: TariffCardOptionProps[];
  isPurple?: boolean;
  tariffInfoCard?: boolean;
}

export const HomeInternetCardContent: FC<HomeInternetCardContentProps> = ({
  optionsHomeInternet,
  mobileOptions,
  isPurple,
  tariffInfoCard,
}) => {
  const labelStyle = isPurple ? styles.option__label__isHIPage : styles.option__label;
  // const { sliderSpeed } = useAppSelector((state) => state.homeInternet);

  return (
    <div className={styles.option__homeBlock}>
      <div>
        {optionsHomeInternet?.length !== 0 && <span className={labelStyle}>Домашний интернет</span>}
        <div className={cn(styles.options, tariffInfoCard && styles.options__infoCard)}>
          {optionsHomeInternet?.map((option, index) => <TariffCardOption key={index} {...option} purple={isPurple} />)}
        </div>
      </div>

      {mobileOptions && mobileOptions.length > 0 && (
        <div>
          <span className={labelStyle}>Мобильная связь</span>
          <div className={cn(styles.options, tariffInfoCard && styles.options__infoCard)}>
            {mobileOptions
              ?.filter(({ icon }) => icon !== 'unlimited')
              .map((option, index) => <TariffCardOption key={index} {...option} purple={isPurple} />)}
          </div>
        </div>
      )}
    </div>
  );
};
