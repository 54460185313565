import {
  Accordion,
  AccordionControlProps as MantineAccordionControlProps,
  AccordionItemProps as MantineAccordionItemProps,
  AccordionPanelProps as MantineAccordionPanelProps,
} from '@mantine/core';
import { FC, ReactNode, memo } from 'react';
import styles from './accordion.module.scss';
import { Checked } from '@/shared/assets/svg';
import cn from 'classnames';

export enum ItemStatus {
  DISABLED,
  IN_PROGRESS,
  FILLED,
}

export interface AccordionItemProps extends MantineAccordionItemProps {
  controlProps?: MantineAccordionControlProps;
  panelProps?: MantineAccordionPanelProps;
  panel?: ReactNode;
  control?: ReactNode;
  idx: number;
  status?: ItemStatus;
}

const AccordionItem: FC<AccordionItemProps> = memo(({ value, panel, control, status, idx }) => {
  return (
    <Accordion.Item value={value}>
      <Accordion.Control>
        <div className={cn(styles.status, { [styles.status__disabled]: status === ItemStatus.DISABLED })}>
          {status === ItemStatus.FILLED ? <Checked /> : idx + 1}
        </div>
        <div className={cn(styles.item, { [styles.item__disabled]: status === ItemStatus.DISABLED })}>{control}</div>
      </Accordion.Control>
      <Accordion.Panel>{panel}</Accordion.Panel>
    </Accordion.Item>
  );
});

AccordionItem.displayName = 'AccordionItem';
export { AccordionItem };
