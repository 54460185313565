import { FC, memo, useState } from 'react';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import isWeekend from 'date-fns/isWeekend';
import cn from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './calendar.module.scss';

interface Calendar extends Partial<ReactDatePickerProps> {
  onDate?: (d: Date) => void;
  initialValue?: Date;
}

const Calendar: FC<Calendar> = memo(
  ({ dateFormat = 'dd.MM.y', initialValue, startDate = new Date(), onDate, ...restProps }) => {
    const { calendarClassName, ...rest } = restProps;
    registerLocale('ru', ru);

    const [date, setDate] = useState(initialValue || new Date());
    const changeHandler = (d: Date) => {
      onDate && onDate(d);
      setDate(d);
    };

    return (
      <DatePicker
        selected={date}
        startDate={startDate}
        onChange={changeHandler}
        minDate={startDate}
        dateFormat={dateFormat}
        calendarClassName={cn(styles.calendar, calendarClassName)}
        filterDate={(d) => !isWeekend(d)}
        locale='ru'
        inline
        {...rest}
      />
    );
  },
);

Calendar.displayName = 'Calendar';
export { Calendar };
